<template>
  <section class="section">
    <div class="section-header">
      <h1>Ordering</h1>
    </div>
    <div class="section-body" ref="formProducts">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              <text-input
                :value.sync="filter.search"
                ref="searchInput"
                @update:value="getData"
                placeholder="Search"
              ></text-input>
            </div>
            <div class="col-2 align-center">
              <div class="form-group">
                <button class="btn btn-secondary w-100" @click="resetFilter">
                  Reset
                </button>
              </div>
            </div>
            <div class="col-2 align-center">
              <div class="form-group">
                <button class="btn btn-primary w-100" @click="focusForm">
                  Proceed
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <select-input
                :value.sync="filter.category_id"
                @update:value="getData"
                :options="data.categories"
              ></select-input>
            </div>
            <div class="col-6">
              <select-input
                :value.sync="filter.type_id"
                @update:value="getData"
                :options="data.types"
              ></select-input>
            </div>
          </div>
          <div
            class="row"
            style="overflow: scroll; height: calc(100vh - 200px); overflow-x: hidden"
          >
            <div class="col-12">
              <div class="row">
                <div
                  class="col-3"
                  data-toggle="modal"
                  v-for="e in products"
                  :key="e.id"
                  :data-target="`#exampleModal-${e.id}`"
                >
                  <div
                    class="card"
                    style="min-height: 125px"
                    @click="addToCart($event, e)"
                  >
                    <div
                      :class="{
                        'card-body': true,
                        'bg-primary-200': productIsSelected(e.id),
                      }"
                    >
                      <div class="row">
                        <div class="col-4">
                          <div
                            class="card-body rounded"
                            :style="{
                              backgroundImage: `url(${e.image})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                            }"
                          ></div>
                        </div>
                        <div class="col-8">
                          <small
                            class="font-weight-bold text-uppercase"
                            style="font-size: .75em;
                              height: calc(.75em * 3 * 1.2);
                              -webkit-line-clamp: 2;
                              display: -webkit-box;
                              -webkit-box-orient: vertical;
                              overflow: hidden;"
                            >{{ e.name }}</small
                          >
                          <br />
                          <small style="font-style: .65em"
                            >Rp. {{ e.price_venue }}</small
                          >
                          <br />
                          <small
                            class="font-italic text-disabled"
                            style="font-size: .5em"
                            >{{ e.category_name }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['w-100', 'bg-whitesmoke', 'overflow-auto', 'mt-lg-5', 'mt-1']"
      :style="{ maxHeight: '80vh', overflow: 'hidden', overflowX: 'hidden' }"
      id="form-customer-order"
      ref="formCustomerOrder"
    >
      <div class="card">
        <div class="card-header">
          <h4>Customer Order</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <text-input
              :value.sync="form.customer_name"
              label="Customer Name"
              classes="form-group col-8"
              placeholder="Customer Name"
            ></text-input>
            <text-input
              :value.sync="form.table_name"
              label="Table Number"
              classes="form-group col-2"
              placeholder="Table Number"
            >
            </text-input>
            <text-input
              :value.sync="form.order_time"
              label="Order Time"
              classes="form-group col-2"
              disabled="disabled"
            ></text-input>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row" v-for="cart in carts" :key="cart.product_id">
                <div
                  class="col-12 pt-2 pb-2"
                  style="border-top: 1px #C8C8C8 solid; border-bottom: 1px #C8C8C8 solid"
                >
                  <div class="row">
                    <div
                      class="col-auto"
                      :style="{
                        backgroundImage: `url(${cart.image})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        minWidth: '120px',
                      }"
                    ></div>
                    <div class="col-7">
                      <h5 class="font-weight-light">{{ cart.name }}</h5>
                      <div class="font-italic">{{ cart.price }}</div>
                      <button
                        class="badge badge-primary my-2"
                        data-toggle="modal"
                        :data-target="`#exampleModal-${cart.id}`"
                      >
                        Edit Cart
                      </button>
                      <div
                        class="py-1 w-100"
                        v-for="(item, indexItem) in cart.additionals"
                        :key="indexItem"
                        style="border-top: 1px #C8C8C8 solid; border-bottom: 1px #C8C8C8 solid"
                      >
                        <div v-for="additional in item" :key="additional.id">
                          <div v-if="additional.count != 0">
                            {{ additional.count }} -
                            {{ additional.parent_product_name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col text-right align-self-center">
                      <div class="row justify-content-end">
                        <button
                          class="btn btn-primary mx-2"
                          @click="decreaseProductCount($event, cart.id)"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          inputmode="numeric"
                          class="form-control text-center d-inline-block"
                          style="width: 5em"
                          v-model="cart.count"
                          min="0"
                        />
                        <button
                          class="btn btn-primary mx-2"
                          @click="increaseProductCount($event, cart.id)"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="form-group w-100">
              <div class="row justify-content-around">
                <button class="btn btn-danger col-3" @click="resetCart">
                  Cancel
                </button>
                <button class="btn btn-info col-3" @click="focusProducts">
                  Add Something More
                </button>
                <button class="btn btn-primary col-3" @click="submitPurchase">
                  Purchase
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="`exampleModal-${cart.id}`"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="false"
      v-for="(cart, cartIndex) in carts"
      :key="cart.id"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable w-100"
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            :style="{
              backgroundImage: `url(${cart.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }"
          >
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <h5>{{ cart.name }}</h5>
                <p>Additional</p>
              </div>
            </div>
            <div>
              <v-app>
                <v-carousel
                  v-model="cart.additional_page"
                  height="100%"
                  :show-arrows="true"
                  :show-arrows-on-hover="true"
                  progress-color="#000"
                  :continuous="false"
                  :hide-delimiter-background="true"
                >
                  <v-carousel-item
                    v-for="(item, itemIndex) in cart.additionals"
                    :key="itemIndex"
                  >
                    <v-sheet tile>
                      <v-row
                        v-for="(additional, additionalIndex) in item"
                        :key="additional.id"
                        style="border-top: 1px #C8C8C8 solid; border-bottom: 1px #C8C8C8 solid"
                      >
                        <div class="col-8">
                          <div class>{{ additional.product_name }}</div>
                          <small>+ {{ additional.price_venue }}</small>
                        </div>
                        <div class="col-4">
                          <button
                            class="btn btn-primary mx-2"
                            @click="
                              removeAdditional(
                                $event,
                                cartIndex,
                                itemIndex,
                                additionalIndex
                              )
                            "
                          >
                            -
                          </button>
                          <small class="mx-1" v-text="additional.count"></small>
                          <button
                            class="btn btn-primary mx-2"
                            @click="
                              addAdditional(
                                $event,
                                cartIndex,
                                itemIndex,
                                additionalIndex
                              )
                            "
                          >
                            +
                          </button>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-app>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-auto">
              <button
                class="btn btn-primary mx-2"
                @mousedown="decreaseProductCount($event, cart.id)"
              >
                -
              </button>
              <small class="mx-1" v-text="cart.count"></small>
              <button
                class="btn btn-primary mx-2"
                @mousedown="increaseProductCount($event, cart.id)"
              >
                +
              </button>
            </div>
            <div class="col-8 text-right">
              <button
                type="button"
                class="btn btn-secondary mr-1"
                data-dismiss="modal"
                @click="removeFromCart($event, cart.id)"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary ml-1"
                data-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.bg-primary-200 {
  background-color: #007bff40;
}
</style>

<script>
import {
  OrderRepository,
  ProductCategoryRepository,
  ProductRepository,
  ProductTypeRepository,
  ProfileRepository,
} from "../../repositories/RepositoryFactory";
export default {
  name: "DashboardOrdering",
  data() {
    return {
      foo: 0,
      carts: [],
      products: {},
      filter: {
        search: "",
        category_id: "",
        type_id: "",
      },
      data: {
        categories: [],
        types: [],
      },
      form: {
        customer_name: "",
        table_name: "",
        venue_id: "",
        order_time: new Date().toDateString(),
      },
      profile: {},
    };
  },
  mounted() {
    ProfileRepository.get()
      .then(({ data }) => {
        this.profile = data.data;
        this.form.venue_id = data.data.venue_id;
      })
      .finally(() => {
        this.getData();
      });
    ProductCategoryRepository.getForSelect().then(({ data }) => {
      this.data.categories = data.data;
    });
    ProductTypeRepository.getForSelect().then(({ data }) => {
      this.data.types = data.data;
    });
  },
  methods: {
    getData() {
      this.products = this.$store.state.productsForOrder;
      ProductRepository.getAll({
        search: this.filter.search,
        category_id: this.filter.category_id,
        type_id: this.filter.type_id,
        venue_id: this.profile.venue_id,
      }).then(({ data }) => {
        data = data.data.map((e) => {
          e.additionals.map((add) => {
            add.count = 0;
            return add;
          });
          if (e.additionals) {
            e.additionals = [e.additionals];
          } else {
            e.additionals = [];
          }
          return e;
        });
        console.log(data);
        this.$store.state.productsForOrder = this.products = data;
      });
    },
    addAdditional(event, cartIndex, itemIndex, additionalIndex) {
      let current_value = this.carts[cartIndex].additionals[itemIndex][
        additionalIndex
      ].count;

      this.$set(
        this.carts[cartIndex].additionals[itemIndex][additionalIndex],
        "count",
        ++current_value
      );
    },
    removeAdditional(event, cartIndex, itemIndex, additionalIndex) {
      let current_value = this.carts[cartIndex].additionals[itemIndex][
        additionalIndex
      ].count;

      if (current_value > 0) {
        this.$set(
          this.carts[cartIndex].additionals[itemIndex][additionalIndex],
          "count",
          --current_value
        );
      }
    },
    increaseProductCount(event, productId) {
      let data = this.carts.filter((e) => e.id == productId)[0];
      let additionals = JSON.parse(
        JSON.stringify(data.additionals[data.additionals.length - 1])
      );
      additionals = additionals.map((e) => {
        e.count = 0;
        return e;
      });
      data.count++;
      data.additional_page++;
      data.additionals.push(additionals);
    },
    decreaseProductCount(event, productId) {
      let data = this.carts.filter((e) => e.id == productId)[0];
      data.count--;
      data.additional_page--;
      if (data.count <= 0) {
        return (this.carts = this.carts.filter((e) => e.id != productId));
      }
      data.additionals.pop();
      this.$forceUpdate();
    },
    submitPurchase() {
      let carts = { carts: JSON.parse(JSON.stringify(this.carts)) };
      carts.carts = carts.carts.map((e) => {
        e.product_id = e.id;
        e.additionals = e.additionals.map((additional) => {
          additional = additional
            .map((item) => {
              if (item.count > 0) return item;
            })
            .filter(Boolean);
          return additional;
        })[0];
        return e;
      });
      Object.assign(carts, this.form, {
        payment_type_id: 1,
      });
      OrderRepository.create(carts)
        .catch((err) => {
          this.$noty.error("Ordering failed, please contact manager quickly");
          console.log(err);
        })
        .finally(() => {
          this.$noty.success("Ordering has been made");
          this.carts = [];
          this.form = {
            customer_name: "",
            order_time: new Date().toDateString(),
            table_name: "",
          };
        });
    },
    resetCart() {
      this.carts = [];
    },
    resetFilter() {
      Object.entries(this.filter).forEach((e) => {
        this.filter[e[0]] = "";
      });
      this.$forceUpdate();
    },
    focusForm() {
      this.$refs.formCustomerOrder.scrollIntoView();
    },
    focusProducts() {
      this.$refs.formProducts.scrollIntoView();
    },
    productIsSelected(id) {
      return this.carts.find((e, i) => e.id == id);
    },
    removeFromCart(event, productId) {
      this.carts = this.carts.filter((e) => e.id != productId);
    },
    addToCart(event, product) {
      if (this.carts.filter((e) => e.id == product.id).length == 0) {
        product.count = 1;
        let obj = Object.assign({}, product);
        this.carts.push(obj);
      } else {
        this.carts = this.carts.filter((e) => e.id != product.id);
      }
    },
  },
  computed: {
    currentCart() {
      return this.carts[this.carts.length - 1].additionals[
        this.carts[this.carts.length - 1].additionals.length - 1
      ];
    },
  },
};
</script>
